<template>
	<v-dialog v-model="editMode" persistent max-width="600px">
		<template v-slot:activator="{ on }">
			<v-btn icon color="warning" v-on="on">
				<v-icon>mdi-pencil</v-icon>
			</v-btn>
		</template>
		<v-card rounded="xl">
			<v-card-title>
				<span class="headline">{{ $t('offers.requisites') }}</span>
				<v-spacer />
				<v-btn icon @click="switchEditMode()">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-autocomplete
						v-model="sectors"
						:label="$t('companies.sectors')"
						:items="availableSectors"
						item-text="name"
						item-value="id"
						:rules="rules.sectors"
						chips
						deletable-chips
						multiple
						return-object
						outlined
						rounded
						hide-details="auto"
						class="pb-3"
						:loading="loading"
						:search-input.sync="search"
						:filter="customFilter"
						hide-selected
					>
						<template #no-data>
							<v-list-item v-if="search == null">
								<v-list-item-title>
									<strong>{{ $t('search.search') }}</strong>
								</v-list-item-title>
							</v-list-item>
							<v-list-item v-else>
								<v-list-item-title>
									{{ $t('search.noResults') }}
								</v-list-item-title>
							</v-list-item>
						</template>
					</v-autocomplete>
					<v-textarea
						v-model="qualifications"
						:label="$t('offers.qualifications')"
						:counter="qualificationsMaxLength"
						:rules="rules.qualifications"
						outlined
						rounded
						hide-details="auto"
						class="pb-3"
						auto-grow
					/>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="blue darken-1" text rounded plain @click="callEdit()">
					{{ $t('settings.edit') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

// generic accent removal from input string, add any missing characters
function removeAccents(input) {
	if (!input) return ''
	const letters1 = 'äáàâăëéèêĕíìüúùûŭöóòôŏÄÁÀÂĂËÉÈÊĔÜÚÙÛŬÖÓÒÔŎßșȘ'
	const letters2 = 'aaaaaeeeeeiiuuuuuoooooAAAAAEEEEEUUUUUOOOOOssS'
	const patternLetters = new RegExp('[' + letters1 + ']', 'g')
	const lookupLetters = {}
	letters1.split('').forEach(function(letter, i) {
		lookupLetters[letter] = letters2[i]
	})
	const letterTranslator = function(match) {
		return lookupLetters[match] || match
	}
	return input.replace(patternLetters, letterTranslator)
}

export default {
	name: 'OfferNeedsUpdateForm',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false,
			editMode: false,
			valid: false,
			qualifications: '',
			search: null,
			sectors: [],
			rules: {
				qualifications: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.qualificationsMaxLength) || this.$t('rules.length', { length: this.qualificationsMaxLength })
				],
				sectors: [
					(v) => !!v.length || this.$t('rules.required'),
					(v) => (v && this.allItemsInArray(v, this.availableSectors)) || this.$t('rules.notSupportedAtleast')
				]
			}
		}
	},
	computed: {
		qualificationsMaxLength() {
			return 1024 * 2
		},
		...mapGetters({
			offer: 'offers/offer',
			availableSectors: 'companies/availableSectors'
		})
	},
	watch: {
		search(val) {
			if (val) this.queryAvailableSectors(val)
		}
	},
	created() {
		this.queryAvailableSectors('')
		this.qualifications = this.offer.info.qualifications
		this.sectors = this.offer.info.sectors
	},
	methods: {
		allItemsInArray(items, array) {
			for (let index = 0; index < items.length; index++) {
				if (!array.map((option) => option.key).includes(items[index].key)) return false
			}
			return true
		},
		switchEditMode() {
			this.editMode = !this.editMode
		},
		queryAvailableSectors(search) {
			this.loading = true
			this.fetchAvailableSectors({ keywords: search }).then(() => {
				this.loading = false
			})
		},
		customFilter(item, queryText) {
			const text = removeAccents(item.name.toLowerCase())
			const searchText = removeAccents(queryText.toLowerCase())
			return text.includes(searchText)
		},
		callEdit() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.editOffer({
				id: this.offer.id,
				externalLink: this.offer.externalLink,
				title: this.offer.title,
				city: this.offer.info.location.city,
				province: this.offer.info.location.province,
				country: this.offer.info.location.country,
				description: this.offer.info.description,
				startingDate: this.offer.info.startingDate,
				duration: this.offer.info.duration,
				offerType: this.offer.info.type,
				fulltime: this.offer.info.time.key,
				thesisOptions: this.offer.info.thesisOptions,
				salary: this.offer.info.salary,
				benefits: this.offer.info.benefits,
				sectors: this.sectors,
				qualifications: this.qualifications
			}).then(() => {
				this.switchEditMode()
				this.loading = false
			})
		},
		...mapActions('offers', ['editOffer']),
		...mapActions('companies', ['fetchAvailableSectors'])
	}
}
</script>
